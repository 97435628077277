<template>
  <div>
    <VTextField
      ref="fileTextField"
      v-model="filename"
      class="mt-0 pt-0"
      prepend-icon="attach_file"
      single-line
      :label="label"
      hint="Select Resume File For Upload"
      persistent-hint
      dark
      :required="required"
      :disabled="disabled"
      @click.native="onFocus"
    ></VTextField>
    <BaseButton
      class="ml-0 mt-3 mb-4"
      small
      round
      color="white"
      text-color="treps-navy"
      :disabled="disabled"
      @click.native="onFocus"
      >Select File</BaseButton
    >
    <input
      ref="fileInput"
      type="file"
      :accept="accept"
      :multiple="multiple"
      :disabled="disabled"
      @change="onFileChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Array, String],
      default: undefined,
    },
    accept: {
      type: String,
      default: '*',
    },
    label: {
      type: String,
      default: 'Choose A File',
    },
    required: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filename: '',
    }
  },
  watch: {
    value(v) {
      this.filename = v
    },
  },
  mounted() {
    this.filename = this.value
  },

  methods: {
    onFocus() {
      if (!this.disabled) {
        this.$refs.fileInput.click()
      }
    },
    onFileChange($event) {
      const files = $event.target.files || $event.dataTransfer.files
      let file = files[0]
      if (files) {
        if (files.length > 0) {
          this.filename = [...files].map((file) => file.name).join(', ')
        } else {
          this.filename = null
        }
      } else {
        this.filename = $event.target.value.split('\\').pop()
      }
      this.$emit('input', this.filename)
      this.$emit('fileSelect', file)
    },
  },
}
</script>

<style scoped>
input[type='file'] {
  position: absolute;
  left: -99999px;
}
</style>
